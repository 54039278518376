@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/corner-radius';

.attachment {
  display: flex;

  &__preview-wrapper {
    border-radius: corner-radius.$xs;
    border: 1px solid light.$ge-border-default;
    display: flex;
  }

  &__preview-button {
    padding: spacing.$xxs;
    border: none;
    background: none;
    outline: none;
    display: flex;
    align-items: center;
    gap: spacing.$xs;
    cursor: pointer;
  }
}
